import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Grid, Link, Stack } from '@mui/material';
import {
  activeQuadrantTabsState,
  homeEditModalOpenState,
  homeQuadrantsState,
} from '../../states/home';
import { useTheme } from '@mui/material/styles';
import {
  HiroOverview,
  HomeQuadrantTabId,
  ProductType,
  QuadrantId,
  SearchHandlerFunction,
  SuggestionSearchData,
  Quadrant as QuadrantType,
  QuadrantTabCategory,
} from '../../types';
import useTooltips from '../../hooks/useTooltips';
import useHasVixAccess from 'hooks/iVol/useHasVixAccess';
import { UpsellModal } from 'components';
import { Popup } from 'components/home/Popup';
import {
  currentToastState,
  hiroSymbolsState,
  searchHandlerState,
  searchSuggestionsData,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
  userDetailsState,
} from 'states';
import { useNavigate } from 'react-router-dom';
import { COMBINED_SIGNALS, EQUITYHUB_UPSELL, HIRO_UPSELL } from 'config';
import useEquities from 'hooks/equityhub/useEquities';
import useSetSym from '../../hooks/hiro/useSetSym';
import { unionBy } from 'lodash';
import QuadrantEditorModal from './Quadrant/Editor/QuadrantEditorModal';
import Quadrant from './Quadrant/Quadrant';
import {
  ALL_HOME_QUADRANT_TABS,
  HOME_DEFAULT_QUADRANTS_MAP,
} from 'config/home';
import useUserDetails from 'hooks/user/useUserDetails';
import useAuth from 'hooks/auth/useAuth';
import useResetEhModel from 'hooks/useResetEhModel';

export const Home = () => {
  useResetEhModel();
  const setToast = useSetRecoilState(currentToastState);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);

  // 4 quadrants, use map keys 1-4 to reference them
  const quadrants = useRecoilValue(homeQuadrantsState);

  const [draftQuadrants, setDraftQuadrants] =
    useState<Map<QuadrantId, QuadrantType>>(quadrants);

  const [activeQuadrantTabs, setActiveQuadrantTabs] = useRecoilState(
    activeQuadrantTabsState,
  );

  const [isEditModalOpen, setIsEditModalOpen] = useRecoilState(
    homeEditModalOpenState,
  );

  const navigate = useNavigate();

  const userDetails = useRecoilValue(userDetailsState);
  const [hiroUpsell, setHiroUpsellOpen] = useState<boolean>(false);
  const [ehUpsell, setEhUpsellOpen] = useState<boolean>(false);
  const stocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const hiroSymbols = useRecoilValue(hiroSymbolsState);
  const [symChangeLoadingMap, setSymChangeLoadingMap] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());

  const { hasAccessToProduct } = useAuth();
  const hasEhAccess = hasAccessToProduct(ProductType.EQUITYHUB);

  const { saveSgSettings } = useUserDetails();
  const { getSymbols } = useEquities();
  const { getSymUrl, setSym } = useSetSym();

  const { loadTooltips } = useTooltips();

  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);

  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);

  useEffect(() => {
    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (val: string): void => {
      const sym = val.toUpperCase();
      setSym(sym, ProductType.HOME);
      const url = getSymUrl(sym);
      if (url != null) {
        return navigate(url);
      }

      if (COMBINED_SIGNALS.has(sym)) {
        setHiroUpsellOpen(true);
      } else {
        setEhUpsellOpen(true);
      }
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [setSuggestionsHandler, hiroSymbols, hasEhAccess, navigate, setSym]);

  useEffect(() => {
    async function fetchEquities() {
      try {
        const symsObj: Record<string, string> = await getSymbols();
        const ehStockSuggestions: SuggestionSearchData[] = Object.entries(
          symsObj,
        ).map(([sym, name]: [string, string]) => ({
          symbol: sym,
          name: name,
        }));
        const hiroStockSuggestions: SuggestionSearchData[] = Array.from(
          stocks.values(),
        ).map((data: HiroOverview) => ({
          symbol: data.instrument,
          name: data.companyName,
        }));
        setSuggestionsData(
          unionBy(ehStockSuggestions, hiroStockSuggestions, 'symbol'),
        );
      } catch (err) {
        console.error(err);
        setToast({
          message:
            'Something went wrong while fetching equities data. Try again or contact us if the issue persists.',
          type: 'error',
        });
      }
    }
    fetchEquities();
  }, [getSymbols, setSuggestionsData, setToast, stocks]);

  useEffect(() => {
    const currentTabs = [...quadrants.values()].flatMap(
      (q: QuadrantType) => q.tabs,
    );
    const activeTabIds = [...activeQuadrantTabs.values()];
    if (
      activeQuadrantTabs.size === 0 ||
      !activeTabIds.every(
        (id: string | undefined) =>
          !id || currentTabs.map((t) => t.id).includes(id),
      )
    ) {
      setActiveQuadrantTabs(
        new Map(
          Array.from(quadrants, ([quadrantId, { tabs }]) => [
            quadrantId,
            tabs[0]?.id,
          ]),
        ),
      );
    }
  }, [quadrants, activeQuadrantTabs]);

  useEffect(() => {
    loadTooltips();
  }, []);

  const handleChartSymChange = async (newSym: string, tabId: string) => {
    setSymChangeLoadingMap((prev) => {
      const newLoadingState = new Map(prev);
      newLoadingState.set(tabId, true);
      return newLoadingState;
    });
    const updatedQuadrantsMap = Array.from(quadrants.entries()).map(
      ([quadrantId, quadrant]): [QuadrantId, QuadrantType] => {
        const updatedTabs = quadrant.tabs.map((tab) =>
          tab.id === tabId ? { ...tab, sym: newSym } : tab,
        );

        return [quadrantId, { ...quadrant, tabs: updatedTabs }];
      },
    );
    const resp = await saveSgSettings(
      {
        homeQuadrants: Object.fromEntries(new Map(updatedQuadrantsMap)) as {
          [key in QuadrantId]: QuadrantType;
        },
      },
      true,
    );

    if (resp?.error != null) {
      setToast({
        message:
          'Something went wrong while changing the symbol. Try again or contact us if the issue persists.',
        type: 'error',
        duration: 10_000,
      });
    } else {
      setDraftQuadrants(new Map(updatedQuadrantsMap));
      setSymChangeLoadingMap((prev) => {
        const newLoadingState = new Map(prev);
        newLoadingState.set(tabId, false);
        return newLoadingState;
      });
    }
  };

  const changeQuadrantActiveTab = (quadrantId: QuadrantId, newTabId: string) =>
    setActiveQuadrantTabs((oldActiveQuadrants) =>
      new Map(oldActiveQuadrants).set(quadrantId, newTabId),
    );
  const hasVixAccess = useHasVixAccess();

  const allAvailableQuadrantTabs = useMemo(() => {
    return hasVixAccess
      ? ALL_HOME_QUADRANT_TABS
      : ALL_HOME_QUADRANT_TABS.filter(
          (el) => el.id !== HomeQuadrantTabId.VIX_CHART,
        );
  }, [ALL_HOME_QUADRANT_TABS, hasVixAccess]);

  return (
    <Stack
      sx={{
        gap: 5,
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Grid container spacing={3} sx={{ flexGrow: 1 }}>
        {[...quadrants.entries()].map(([idx, quadrant]) => (
          <Grid key={idx} item xs={12} lg={6}>
            <Quadrant
              key={idx}
              tabs={quadrant.tabs}
              setEditorOpen={setIsEditModalOpen}
              activeTabId={activeQuadrantTabs.get(idx)}
              setActiveTabId={(id: string) => changeQuadrantActiveTab(idx, id)}
              onSymChange={handleChartSymChange}
              symChangeLoadingMap={symChangeLoadingMap}
            />
          </Grid>
        ))}
      </Grid>

      <QuadrantEditorModal
        title="Customize Market Overview"
        quadrants={quadrants}
        draftQuadrants={draftQuadrants}
        setDraftQuadrants={setDraftQuadrants}
        settingsSaveKey="homeQuadrants"
        setActiveQuadrantTabs={setActiveQuadrantTabs}
        setIsOpen={setIsEditModalOpen}
        isOpen={isEditModalOpen}
        allAvailableQuadrantTabs={allAvailableQuadrantTabs}
        defaultQuadrantsMap={HOME_DEFAULT_QUADRANTS_MAP}
        availableContentCategories={[...Object.values(QuadrantTabCategory)]}
      />

      <Footer />

      {displayPopup && <Popup setDisplayPopup={setDisplayPopup} />}
      <UpsellModal
        open={hiroUpsell}
        setOpen={setHiroUpsellOpen}
        title={HIRO_UPSELL.title}
        subtitle={
          userDetails != null
            ? HIRO_UPSELL.upgrade_subtitle
            : HIRO_UPSELL.subtitle
        }
        items={HIRO_UPSELL.items}
      />
      <UpsellModal
        open={ehUpsell}
        setOpen={setEhUpsellOpen}
        title={EQUITYHUB_UPSELL.title}
        subtitle={EQUITYHUB_UPSELL.subtitle}
        items={EQUITYHUB_UPSELL.items}
      />
    </Stack>
  );
};

const Footer = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={{
        gap: '10px',
        justifyContent: 'center',
        paddingBottom: '5px',
      }}
    >
      <Link
        sx={{
          color: theme.palette.gray,
          fontSize: '12px',
          textDecoration: 'none',
        }}
        href="https://spotgamma.com/terms-and-conditions/"
        target="_blank"
      >
        Terms and Conditions
      </Link>
      <Link
        sx={{
          color: theme.palette.gray,
          fontSize: '12px',
          textDecoration: 'none',
        }}
        href="https://spotgamma.com/privacy-policy/"
        target="_blank"
      >
        Privacy Policy
      </Link>
      <Link
        sx={{
          color: theme.palette.gray,
          fontSize: '12px',
          textDecoration: 'none',
        }}
        href="https://spotgamma.com/model-faq/disclaimer/"
        target="_blank"
      >
        Disclaimer
      </Link>
    </Stack>
  );
};
