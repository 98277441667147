import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from '@spotgamma/x-data-grid-premium';
import {
  defaultColumnScannerVisibility,
  scannerFields,
  EQUITYHUB_UPSELL,
} from '../../config';
import useAuth from '../../hooks/auth/useAuth';
import usePrices from '../../hooks/equityhub/usePrices';
import useActiveScanner from '../../hooks/scanners/useActiveScanner';
import useCleanup from '../../hooks/useCleanup';
import {
  synthesizedEquitiesState,
  currentToastState,
  pricesState,
  equitiesFetchLoadingState,
  watchlistsState,
  selectedEquitySymbolState,
} from '../../states';
import {
  scannerActiveWatchlistsIdsState,
  useDefaultColumnsState,
  scannerColumnsVisibilityState,
  activeScannerState,
  scannerDataState,
  scannerPinnedColumnsState,
  scannerSortModelState,
  scannerFilterModelState,
  scannerColumnOrderState,
} from '../../states/scanners';
import {
  ProductType,
  LegacyEquity,
  SynthOIEquity,
  Scanner,
  CoreEquity,
} from '../../types';
import {
  hasKeyEquityFields,
  getColumnsForScanner,
  getEquitiesForScanner,
  minVolumeThresholdFilter,
} from '../../util';
import {
  useEquityGridColumns,
  EquityTableType,
} from '../equityhub/EquityhubTable/useEquityScannerGridColumns';
import { InfoButton, UpsellModal, WatchlistMultiSelect } from '../shared';
import { EquityScannerGrid } from './EquityScannerGrid';
import PanelFoldButton from 'components/shared/PanelFoldButton';
import PanelFullscreenButton from 'components/shared/PanelFullscreenButton';
import ScannersSection from './ScannersSection';
import { compassActiveSymbolsState } from 'states/compass';

export const EquityStockScanner = () => {
  useCleanup();
  const theme = useTheme();
  const equities = useRecoilValue(synthesizedEquitiesState);
  const setToast = useSetRecoilState(currentToastState);
  const setPrices = useSetRecoilState(pricesState);
  const [pricesLoading, setPricesLoading] = useState<boolean>(false);
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const watchlists = useRecoilValue(watchlistsState);
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    scannerActiveWatchlistsIdsState,
  );
  const [dpiRange, setDPIRange] = useState<number[] | undefined>(undefined);
  const useDefaultColumns = useRecoilValue(useDefaultColumnsState);
  const { hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);
  const [currentSym, setCurrentSym] = useRecoilState(selectedEquitySymbolState);
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const setColumnVisibilityModel = useSetRecoilState(
    scannerColumnsVisibilityState,
  );
  const setCompassActiveSymbols = useSetRecoilState(compassActiveSymbolsState);

  const { activeScanner } = useActiveScanner(activeScannerState);
  const { getPrices } = usePrices();
  const [scannerData, setScannerData] = useRecoilState(scannerDataState);

  const { columns: gridCols, columnGroups: gridColGroups } =
    useEquityGridColumns(
      theme,
      dpiRange,
      EquityTableType.StockScreener,
      !hasScannersAccess
        ? [...equities.values()]
            .filter((e) => !hasKeyEquityFields(e))
            .map((e) => e.sym)
        : undefined,
    );

  useEffect(() => {
    const dpiArray = [...equities.values()].map((d: CoreEquity) => d.dpi);
    setDPIRange([Math.min(...dpiArray), Math.max(...dpiArray)]);
  }, [equities]);

  useEffect(() => {
    async function fetchVisiblePriceData() {
      try {
        setPricesLoading(true);
        // Just fetch all prices. It's a small payload
        const newPrices: Map<string, number> = await getPrices([]);
        setPrices((prices) => new Map([...prices, ...newPrices]));
      } catch (err) {
        console.error(err);
        setToast({
          message:
            'Something went wrong while fetching equities price data. Try again or contact us if the issue persists.',
          type: 'error',
          duration: 10000,
        });
      } finally {
        setPricesLoading(false);
      }
    }
    fetchVisiblePriceData();
  }, [getPrices, setPrices]);

  useEffect(() => {
    if (useDefaultColumns) {
      setColumnVisibilityModel(
        activeScanner
          ? getColumnsForScanner(activeScanner)
          : defaultColumnScannerVisibility,
      );
    }
  }, [useDefaultColumns, activeScanner]);

  useEffect(() => {
    // filters data by search/scanner
    let resultData = [...equities.values()];

    if (activeWatchlistIds.length > 0) {
      const activeWatchlists =
        watchlists?.filter((w) =>
          activeWatchlistIds.includes(w.id as number),
        ) ?? [];

      const syms: Set<string> = new Set(resultData.map((w) => w.sym));
      const watchlistSyms = new Set(activeWatchlists.flatMap((w) => w.symbols));

      resultData = [...watchlistSyms]
        .filter((s) => syms.has(s))
        .map((s) => equities.get(s) as LegacyEquity | SynthOIEquity);
    }

    if (activeScanner) {
      // Apply default filters of (call vol > 5k) and (total options vol > 10k)
      resultData = getEquitiesForScanner(activeScanner, resultData);
      if (activeScanner !== Scanner.CROSS_ASSET_SUMMARY) {
        resultData = resultData.filter(minVolumeThresholdFilter);
      }
    }

    setScannerData(
      hasScannersAccess
        ? resultData
        : resultData.slice().sort((a, b) => {
            const aFull = hasKeyEquityFields(a) ? 1 : 0;
            const bFull = hasKeyEquityFields(b) ? 1 : 0;
            return bFull - aFull;
          }),
    );
  }, [
    activeScanner,
    activeWatchlistIds,
    hasScannersAccess,
    watchlists,
    equities,
  ]);

  const setSelectedRow = (row: LegacyEquity | SynthOIEquity) => {
    const newSym = row.sym;
    setCurrentSym(newSym);
    setCompassActiveSymbols((prev) => Array.from(new Set([...prev, newSym])));
  };

  const isRowDisabled = (row: LegacyEquity | SynthOIEquity) => {
    return !hasScannersAccess && !row.live;
  };

  const onRowClick = (
    params: GridRowParams,
    _event: MuiEvent,
    _details: GridCallbackDetails,
  ) => {
    const row = params.row as LegacyEquity | SynthOIEquity;
    if (isRowDisabled(row)) {
      setUpsellOpen(true);
    } else {
      setSelectedRow(row);
    }
  };

  return (
    <Box
      sx={{
        width: 'fit-content',
        maxWidth: '100%',
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <EquityScannerGrid
        currentSym={currentSym}
        columns={gridCols}
        data={scannerData}
        columnGroups={gridColGroups}
        onRowClick={onRowClick}
        loading={equitiesLoading || pricesLoading}
        activeScanner={activeScanner}
        useDefaultColumnState={useDefaultColumnsState}
        columnVisibilityModelState={scannerColumnsVisibilityState}
        scannerPinnedColumnsState={scannerPinnedColumnsState}
        scannerSortModelState={scannerSortModelState}
        filterModelState={scannerFilterModelState}
        activeWatchlistIdsState={scannerActiveWatchlistsIdsState}
        columnOrderState={scannerColumnOrderState}
        defaultColumnOrder={scannerFields}
        customSettings={
          <Box>
            <InfoButton key="scanners" articleKey="scanners" />
            <WatchlistMultiSelect
              activeWatchlistIds={activeWatchlistIds}
              setActiveWatchlistIds={setActiveWatchlistIds}
            />
            <PanelFullscreenButton panelType="bottom" />
            <PanelFoldButton panelType="bottom" />
          </Box>
        }
        customScannerSettings={
          <ScannersSection
            activeWatchlistIdsState={scannerActiveWatchlistsIdsState}
            activeScannerState={activeScannerState}
          />
        }
        showScannerInfo
      />
      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={EQUITYHUB_UPSELL.title}
        subtitle={EQUITYHUB_UPSELL.subtitle}
        items={EQUITYHUB_UPSELL.items}
      />
    </Box>
  );
};
