import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EhModelType } from 'types';
import { updateSearch } from 'util/shared';

const useResetEhModel = () => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      updateSearch({ 'eh-model': EhModelType.Legacy, date: undefined }),
    );
  }, [setSearchParams]);
};

export default useResetEhModel;
