import { Typography, useTheme } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRowParams,
} from '@spotgamma/x-data-grid-premium';
import {
  useEquityGridColumns,
  EquityTableType,
} from 'components/equityhub/EquityhubTable/useEquityScannerGridColumns';
import { Loader, UpsellModal } from 'components/shared';
import { EQUITYHUB_UPSELL } from 'config';
import useAuth from 'hooks/auth/useAuth';
import useEquities from 'hooks/equityhub/useEquities';
import usePrices from 'hooks/equityhub/usePrices';
import useSynthOi from 'hooks/equityhub/useSynthOi';
import useLog from 'hooks/useLog';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { equityScannersDataState, watchlistsState } from 'states';
import {
  CoreEquity,
  ProductType,
  Scanner,
  EquityFieldKey,
  ScannerExclusiveColumnsCondensed,
  ScannerExclusiveColumnsCondensedWidths,
} from 'types';
import { createDummyEquity, getEquitiesForScanner } from 'util/equityhub';
import {
  defaultGridTableSlots,
  getDefaultGridTableSlotProps,
  getDefaultGridTableStyles,
  updateSearch,
} from 'util/shared';

const dummyEquities = Array.from({ length: 4 }, (_, i) => ({
  id: `test-${i}`,
  isWatchlisted: false,
  ...createDummyEquity(`DE-${i + 1}`),
}));

interface ScannerTableProps {
  scanner: Scanner;
}

const ScannerTable = ({ scanner }: ScannerTableProps) => {
  const theme = useTheme();
  const { logError } = useLog('Home');
  const watchlists = useRecoilValue(watchlistsState);
  const watchlistSyms = new Set(watchlists?.flatMap((w) => w.symbols));
  const [loading, setLoading] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const { hasAccessToProduct } = useAuth();
  const hasEHAccess = hasAccessToProduct(ProductType.EQUITYHUB);

  const [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { getEquityScanners } = useEquities();
  const { getPrices } = usePrices();

  const [equityScannersData, setEquityScannersData] = useRecoilState(
    equityScannersDataState,
  );

  const { isSynthOI } = useSynthOi();

  const { columns } = useEquityGridColumns(
    theme,
    undefined,
    EquityTableType.StockScreener,
    !hasEHAccess ? dummyEquities.map((e) => e.id) : undefined,
  );

  const fetchData = async (isSynthOI: boolean) => {
    try {
      setLoading(true);
      setErrored(false);
      const rawEquityScanners = await getEquityScanners(isSynthOI);

      const currentEquityScannersMap: Map<string, CoreEquity> = new Map(
        rawEquityScanners.map((e: CoreEquity) => [e.sym, e]),
      );

      const prices: Map<string, number> = await getPrices(
        Array.from(currentEquityScannersMap.keys()) as string[],
      );

      prices.forEach((price, sym) => {
        const e = currentEquityScannersMap.get(sym);
        if (e) {
          currentEquityScannersMap.set(sym, {
            ...e,
            price,
          });
        }
      });

      let eqData = Array.from(currentEquityScannersMap.values()).map((e) => ({
        ...e,
        id: e.sym,
        isWatchlisted: watchlistSyms.has(e.sym),
      }));

      if (!hasEHAccess) {
        eqData = [...eqData, ...dummyEquities];
      }

      setEquityScannersData(eqData);
    } catch (err) {
      setErrored(true);
      logError(err, `${scanner} candidates`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(isSynthOI);
  }, [isSynthOI]);

  const setSelectedRow = (row: CoreEquity) => {
    if (!hasEHAccess) {
      return;
    }

    const newSym = row.sym;
    setSearchParams(updateSearch({ sym: newSym }));
    navigate(`/${ProductType.EQUITYHUB}?sym=${newSym}`);
  };

  const onRowClick = (params: GridRowParams) => {
    if (!hasEHAccess) {
      return setUpsellOpen(true);
    }
    setSelectedRow(params.row as CoreEquity);
  };

  const filteredColumns = columns.filter((colDef: GridColDef) =>
    ScannerExclusiveColumnsCondensed[scanner].includes(
      colDef.field as EquityFieldKey,
    ),
  );

  return (
    <Loader isLoading={loading}>
      {errored ? (
        <Typography
          sx={{
            fontSize: '16px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => fetchData(isSynthOI)}
        >
          There was a network error. Click here to retry.
        </Typography>
      ) : (
        <>
          <DataGridPremium
            rows={getEquitiesForScanner(scanner, equityScannersData)}
            columns={filteredColumns.map((colDef) => ({
              ...colDef,
              minWidth: 0,
              width:
                ScannerExclusiveColumnsCondensedWidths.get(
                  colDef.field as EquityFieldKey,
                ) ?? 95,
            }))}
            initialState={{
              pinnedColumns: { left: ['isWatchlisted', 'sym'] },
              sorting: {
                sortModel:
                  scanner === Scanner.VOL_RISK_PREMIUM
                    ? [{ field: EquityFieldKey.ivRank, sort: 'desc' }]
                    : [],
              },
            }}
            rowHeight={47}
            onRowClick={onRowClick}
            disableMultipleRowSelection
            disableColumnPinning
            disableColumnSelector
            disableColumnMenu
            autoHeight
            hideFooter
            disableAggregation
            disableColumnReorder
            columnHeaderHeight={60}
            disableRowGrouping
            density="compact"
            slots={{ ...defaultGridTableSlots }}
            slotProps={{ ...getDefaultGridTableSlotProps(theme) }}
            sx={{
              ...getDefaultGridTableStyles(theme),
              maxWidth: 'fit-content',
            }}
          />
          <UpsellModal
            open={upsellOpen}
            setOpen={setUpsellOpen}
            title={EQUITYHUB_UPSELL.title}
            subtitle={EQUITYHUB_UPSELL.subtitle}
            items={EQUITYHUB_UPSELL.items}
          />
        </>
      )}
    </Loader>
  );
};

export default ScannerTable;
